*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
@font-face {
  font-family: 'Saint Peter';
  font-weight: normal;
}
/*styling navbar*/
nav{
  display: flex;
  padding: 2% 6%;
  justify-content: space-between;
  align-items: center;
}
nav img{
  width: 85px;
 margin-left: 20px;
 margin-top: 10px;
}
.nav-links{
  flex: 1;
  text-align: right;
}
.nav-links ul li{
  list-style: none;
  display: inline-block;
  padding: 10px 20px;
  position: relative;
}
.nav-links ul a{
  color: #70B99B;
  text-decoration: none;
  font-size: 14px;
  font-weight:bold;
}
.nav-links ul li .btn{
  color: #fff;
  border: 1px solid #70B99B;
  background-color: #70B99B;
  padding: 10px 20px;
  border-radius: 10px;
  margin-left: 20px;
}

.nav-links ul li a:hover{
  border-bottom: 3px solid #70B99B;
}

  /*styling home*/
  .header{
    height: 100vh;
    width: 100%;
    background: url(./components/images/chien.png);
    background-size: cover;
    background-position:center;
    position: relative;
  }
  .text-box{
    width: 100%;
    color:#fff;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%,-50%) ;
    text-align: center;
  }
  .text-box h1{
    font-size: 50px;
    font-weight: 900;
    
  }
  .text-box p{
    margin: 10px 0 40px;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
  }
  .hero-btn{
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    border: 2px solid #70B99B;
    background-color: #70B99B;
    padding: 10px 30px;
    border-radius: 10px;
  
  }
  /*styling couverture*/
  #couverture{
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #F5F5F5;
  }
 
  #couverture h1{
    margin-bottom: 20px;
    color: #3D3D3D;  
  }
  #couverture img{
   margin-top: 20px;
    width:250px ;
    height:214 ;
    border-radius: 10px;
   
  }
  
   .floating-div{
    color: #fff;
    font-weight: 500;
    border: 2px solid #70B99B;
    background-color: #70B99B;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    font-size: 13px;
   
   
  }
  
  video{
    width: 100%;
    height: 500px;
    object-fit: cover;
    margin-top: 80px;
  }
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 65rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
  } 
   .content h3{
    color: #fff !important;
  }
  .content p{
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
    
  } 
  /*styling services*/
  #services{
    background-color: #F5F5F5;
  }
  .container img{
    width: 80px;
    margin-top: 50px;
  
  }
  .services-col{
    flex-basis: 31%;
    border-radius: 10px;
    margin-bottom: 5%;
    text-align: center;
  }
  .services-col h4{
    margin-top: 20px;
    font-weight: bold;
    color: #3D3D3D;
  }
  .services-col p{
    margin-top: 20px;
    color: #737373;
    font-weight: 500;
   
  }
   .feedback .container-sm{
    height: 100vh;
    width: 100%;
    background: url(./components/images/cats-dogs.png);
    background-repeat: no-repeat;
    background-position:center;
    position: relative;
    margin-top: 10px;
  
  }
  .feedback-col{
    flex-basis: 31%;
    border-radius: 10px;
    margin-bottom: 5%;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    background-color: #fff;
    
  }
  
  
  .fa.fa-star,.fa-star-o{
    
    color: #FFED00;
  }
  .feedback h5{
    color: #414141;
    font-weight: bold;
    font-size: 20px;
    padding-top: 15px;
    text-align: center;
  }
.feedback   p{
    color: #414141;
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;
    
  }
  .feedback hr{
    color: #414141;
  }

  
/*styling footer*/
.images{
  display: inline;
}
.images img{
  width: 40px;
  margin-top: 0;
  margin: 8px;
}
.images{
  margin-right: -72px;
}
.footer .test{
  margin-left: -180px;
}
.footer .img{
  margin-left: -120px;
}
.footer .test p{
  margin-right: -50px;
  margin-top: 5px;
}
.footer {
  background-color: #414141;
  height: 240px;
}
.footer .col-md-4{
  margin-left: -100px;
 
}
.footer .col-md-3 p,h5 {
  text-align: left;

  font-size: 15px;
}
 .footer2{
  background-color: #70B99B;
  color: #fff;
  padding: 10px;
  font-size: 12px;
}
.footer2 .col-md-8{
  padding-left: 100px;
}
